<template>
  <div class="page page-box page-padding">
    <!-- 页头 -->
    <header class="page-header">
      <el-form :inline="true" :model="listQuery" class="demo-form-inline">
        <el-form-item label="课程名称：">
          <el-input v-model="listQuery.Name" placeholder="请输入课程名称" class="input-width" />
        </el-form-item>
        <el-form-item>
          <el-button v-preventReClick @click="handleSearch" class="discoloration">查询</el-button>
          <el-button class="plain-button" v-preventReClick @click="resetSearch">重置</el-button>
          <el-button @click="addOrEidtClubCourse()" icon="el-icon-plus">
            <!-- <div class="img-center"> <img src="@/assets/add.png" alt="添加" class="add-img"> <span>添加展示课程</span></div> -->
            添加展示课程
          </el-button>
        </el-form-item>
      </el-form>
    </header>
    <!-- 主体内容 -->
    <main class="page-main-main" ref='main'>
      <!--表格渲染-->
      <el-table v-loading="loading" :data="tableList" border fit stripe>
        <el-table-column label="展示课程" prop="Name" align="center" :show-overflow-tooltip="true" />
        <el-table-column label="课程封面" prop="Cover" align="center">
          <template slot-scope="{ row }">
            <div class="img-box">
              <el-image class="image-box" :src="`${cosHttp}${row.Cover}`" :preview-src-list="[`${cosHttp}${row.Cover}`]" />
            </div>
          </template>
        </el-table-column>
        <el-table-column label="课程简介" prop="Content" align="center" :show-overflow-tooltip="true" />
        <el-table-column label="课程价格" align="center">
          <template slot-scope="{row}">
            ￥{{row.Price}}
          </template>
        </el-table-column>
        <el-table-column label="是否展示在家长端" align="center">
          <template slot-scope="{row}">
            <el-switch v-model="row.Enable" active-color="#00B446" inactive-color="black" @change="handleStatusChange(row)" />
            {{row.Enable?"已展示":"未展示"}}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" class-name="small-padding" width="100px">
          <template slot-scope="{ row }">
            <el-button type="text" @click="addOrEidtClubCourse(row)"> 编辑 </el-button>
            <el-button type="text" @click="deleteClubCourse(row)"> 删除 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页部分 -->
      <footer>
        <pagination v-show="total > 0" :total="total" :page.sync="listQuery.PageNo" :limit.sync="listQuery.PageSize" @pagination="getClubShowCourseList" />
      </footer>
    </main>
    <!-- 新建编辑课程 -->
    <el-dialog :title="ClubCourseTitle" :visible.sync="ClubCourseVisible" width="40%" :close-on-click-modal='false'>
      <el-form :model="ClubCourseForm" :rules="rules" ref='ClubCourseForm' label-width="100px">
        <el-form-item label="课程名称:" prop="Name">
          <el-input v-model="ClubCourseForm.Name" placeholder="请输入课程名称" show-word-limit maxlength="20" />
        </el-form-item>
        <el-form-item label="课程价格:" prop="Price">
          <el-input v-model="ClubCourseForm.Price" placeholder="请输入课程价格" type="number" class="no-number" />
        </el-form-item>
        <el-form-item label="封面图片:" prop="Cover">
          <template>
            <div class="cropper-wrapper">
              <!-- element 上传图片按钮 -->
              <template v-if="!isPreview">
                <el-upload action="#" :show-file-list="false" :on-change="handleChangeUpload">
                  <img v-if="ClubCourseForm.Cover" :src="`${cosHttp}${ClubCourseForm.Cover}`" class="avatar">
                  <i v-else class="el-icon-full-screen avatar-uploader-icon avatar-uploader">
                    <br>
                    <span>上传图片</span>
                  </i>
                </el-upload>
                <span class="imgFont">(支持JPG、JPEG、PNG格式图片，20M大小以内)</span>
              </template>
              <div class="pre-box" v-else>
                <el-upload class="upload-demo" action="#" :show-file-list="false" :on-change="handleChangeUpload">
                  <!-- <el-button type="primary" plain>更换图片</el-button> -->
                  <img v-if="previewImg" :src="previewImg" alt="裁剪图片" class="avatar" />
                </el-upload>
                <span class="imgFont">(支持JPG、JPEG、PNG格式图片，20M大小以内)</span>
              </div>
              <!-- vueCropper 剪裁图片实现-->
              <el-dialog title="图片剪裁" :visible.sync="dialogVisible" class="crop-dialog" append-to-body :close-on-click-modal='false'>
                <div class="cropper-content">
                  <div class="cropper" style="text-align: center">
                    <vueCropper ref="cropper" :img="option.img" :outputSize="option.size" :outputType="option.outputType" :info="true" :full="option.full" :canMove="option.canMove" :canMoveBox="option.canMoveBox" :original="option.original" :autoCrop="option.autoCrop" :fixed="option.fixed" :fixedNumber="option.fixedNumber" :centerBox="option.centerBox" :infoTrue="option.infoTrue" :fixedBox="option.fixedBox" :autoCropWidth="option.autoCropWidth" :autoCropHeight="option.autoCropHeight" @cropMoving="cropMoving" />
                  </div>
                </div>
                <div class="action-box">
                  <el-upload class="upload-demo" action="#" :show-file-list="false" :on-change="handleChangeUpload">
                    <el-button type="primary" plain>更换图片</el-button>
                  </el-upload>
                  <el-button type="primary" plain @click="clearImgHandle">清除图片</el-button>
                  <el-button type="primary" plain @click="rotateLeftHandle">左旋转</el-button>
                  <el-button type="primary" plain @click="rotateRightHandle">右旋转</el-button>
                  <el-button type="primary" plain @click="changeScaleHandle(1)">放大</el-button>
                  <el-button type="primary" plain @click="changeScaleHandle(-1)">缩小</el-button>
                  <el-button type="primary" plain @click="downloadHandle('blob')">下载</el-button>
                </div>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="dialogVisible = false">取 消</el-button>
                  <el-button class="discoloration" @click="finish" :loading="loading">确 认</el-button>
                </div>
              </el-dialog>
            </div>
          </template>
        </el-form-item>
        <el-form-item label="是否展示:" prop="Enable" required>
          <el-radio-group v-model="ClubCourseForm.Enable">
            <el-radio :label="false">不展示</el-radio>
            <el-radio :label="true">展示</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="课程简介:" prop="Content">
          <el-input v-model="ClubCourseForm.Content" :autosize="{ minRows: 3, maxRows: 5 }" type="textarea" placeholder="请输入课程简介" show-word-limit maxlength="300" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="ClubCourseVisible = false" class="plain-button">取 消</el-button>
        <el-button v-preventReClick @click="addOrUpdateClubShowCourse()" class="discoloration">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getClubShowCourseList, addOrUpdateClubShowCourse, getClubShowCourseById, delClubShowCourse } from "@/service/organizational";
import pagination from '@/components/pagination/pagination.vue';
import { deepClone } from '@/utils/index';
import upload from '@/mixins/upload';
import { VueCropper } from 'vue-cropper';


const ClubCourseForm = { // 新增编辑课程的初始化数据
  Name: '',
  Cover: '',
  Content: '',
  Price: 0,
  Enable: false
};

const ListQuery = { // 查询数据
  PageNo: 1,
  PageSize: 50,
  Name: '',
};

export default {
  name: "Course",
  mixins: [upload],
  components: {
    pagination,
    VueCropper
  },
  data() {
    const validateName = (rule, value, callback) => {
      if (!value || value.trim() === '') {
        return callback(new Error('请输入课程名称'));
      }
      callback();
    };

    const validatePrice = (rule, value, callback) => {
      this.ClubCourseForm.Price = Number(this.ClubCourseForm.Price);
      if (this.ClubCourseForm.Price === 0 || this.ClubCourseForm.Price > 0) {
        return callback();
      }
      callback(new Error('课程价格应该大于0'));
    };

    const validateCover = (rule, value, callback) => {
      if (!value || value.trim() === '') {
        return callback(new Error('请上传图片'));
      }
      callback();
    };

    const validateContent = (rule, value, callback) => {
      if (!value || value.trim() === '') {
        return callback(new Error('请输入课程简介'));
      }
      callback();
    };

    return {
      fileName: "defualt",
      ClubCourseForm: deepClone(ClubCourseForm),
      listQuery: deepClone(ListQuery),
      tableList: [], // 表格数据
      loading: false, // 加载状态
      total: 0,  // 表格总条数
      ClubCourseTitle: '添加课程', // 新建编辑title
      ClubCourseVisible: false, // 新建编辑课程弹窗状态
      imageUrl: '', // 上传图片
      rules: {
        Name: [{ required: true, validator: validateName, trigger: 'blur' }],
        Price: [{ required: true, validator: validatePrice, trigger: 'blur' }],
        Cover: [{ required: true, validator: validateCover, trigger: 'blur' }],
        Content: [{ required: true, validator: validateContent, trigger: 'blur' }],
      },
      cosHttp: process.env.VUE_APP_COSHTTP,
      Enable: false,
      isPreview: false,
      dialogVisible: false,
      previewImg: '', // 预览图片地址
      // 裁剪组件的基础配置option
      option: {
        img: '', // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 1, // 裁剪生成图片的质量
        outputType: 'png', // 裁剪生成图片的格式
        canScale: true, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        canMoveBox: true, // 截图框能否拖动
        autoCropWidth: 100, // 默认生成截图框宽度
        autoCropHeight: 100, // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [16, 9], // 截图框的宽高比例
        full: false, // 是否输出原图比例的截图
        original: false, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        infoTrue: true // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      // 防止重复提交
      loading: false
    };
  },
  created() {
    this.getClubShowCourseList();
  },
  methods: {
    /**
     * 获取课程列表数据
     */
    async getClubShowCourseList(data) {
      if (data) {
        this.listQuery.PageNo = data.page;
        this.listQuery.PageSize = data.limit;
      }
      this.loading = true;
      let res = await getClubShowCourseList(this.listQuery);
      this.loading = false;
      this.tableList = res.DataList;
      this.total = res.Total;
    },
    /**
     * 查询
     */
    handleSearch() {
      this.listQuery.PageNo = 1;
      this.listQuery.Name = this.listQuery.Name && this.listQuery.Name.trim();
      this.getClubShowCourseList();
    },

    /**
     * 重置
     */
    resetSearch() {
      this.listQuery = deepClone(ListQuery);
      this.getClubShowCourseList();
    },

    /**
     * 新增或者编辑
     */
    addOrEidtClubCourse(row) {
      this.ClubCourseVisible = true;
      this.isPreview = false;
      this.$nextTick(() => {
        this.$refs.ClubCourseForm.clearValidate();
      })
      if (row) {
        this.ClubCourseForm = { ...row };
        this.ClubCourseTitle = '编辑展示课程';
      } else {
        this.ClubCourseForm = deepClone(ClubCourseForm);
        this.ClubCourseTitle = '添加展示课程';
      }
    },

    /**
     * 确定新增或者编辑
     */
    addOrUpdateClubShowCourse() {
      this.$refs.ClubCourseForm.validate(async (valid) => {
        if (!valid) return;

        if (this.ClubCourseTitle === '编辑展示课程') {
          await addOrUpdateClubShowCourse({
            Id: this.ClubCourseForm.Id,
            Cover: this.ClubCourseForm.Cover,
            Content: this.ClubCourseForm.Content,
            Price: this.ClubCourseForm.Price,
            Name: this.ClubCourseForm.Name,
            Enable: this.ClubCourseForm.Enable,
          });
          this.$message.success('编辑成功');
        } else {
          await addOrUpdateClubShowCourse(this.ClubCourseForm);
          this.$message.success('添加成功');
        }

        this.ClubCourseVisible = false;
        this.getClubShowCourseList();
      })
    },

    // 是否展示
    async handleStatusChange(row) {
      try {
        let res = await this.$confirm(`是否${row.Enable === true ? '展示' : '取消展示'}当前课程?`, '提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确 认',
          cancelButtonText: '取 消',
          type: 'warning',
          center: true,
        })
        await addOrUpdateClubShowCourse(
          {
            Id: row.Id,
            Enable: row.Enable,
            Content: row.Content,
            Cover: row.Cover,
            Name: row.Name,
            Price: row.Price
          });
        this.getClubShowCourseList();
        this.$message({
          message: `${row.Enable === true ? '展示课程成功' : '取消展示成功'}`,
          type: 'success',
          center: true
        });
      } catch (err) {
        row.Enable = !row.Enable;
      };
    },

    /**
     * 删除
     */
    async deleteClubCourse(row) {
      try {
        let res = await this.$confirm('确认删除吗？删除后家长端该课程展示将失效。', '警告', {
          distinguishCancelAndClose: true,
          confirmButtonText: '删 除',
          cancelButtonText: '取 消',
          type: 'warning',
          center: true,
        })
        if (res !== 'confirm') return;
        await delClubShowCourse({ Id: row.Id });
        this.getClubShowCourseList();
        this.$message({
          message: '删除成功',
          type: 'success',
        });
      } catch (err) { };
    },

    // 正在上传中...
    uploading(progressData) {
      let index = this.fileList.length - 1;
      this.fileList[index].percentage = progressData.percent * 100;
      this.fileList[index].status = 'uploading';
    },

    // 上传按钮 限制图片大小和类型
    handleChangeUpload(file, fileList) {
      // 上传成功后将图片地址赋值给裁剪框显示图片
      if (JSON.parse(JSON.stringify(file)).status === 'ready') {
        const isJPG = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png' || file.raw.type === 'image/jpg';
        const isLt20M = file.raw.size / 1024 / 1024 < 20;

        if (!isJPG) {
          this.$message.error('上传头像图片只能是 JPG/PNG/JPEG 格式!');
          return false;
        }
        if (!isLt20M) {
          this.$message.error('上传头像图片大小不能超过 20MB!');
          return false;
        }
        this.fileName = file.name.split('.')[0];
        // 上传成功后将图片地址赋值给裁剪框显示图片
        this.$nextTick(async () => {
          this.loading = true;
          // base64方式
          // this.option.img = await fileByBase64(file.raw)
          this.option.img = URL.createObjectURL(file.raw)
          this.loading = false;
          this.dialogVisible = true;
        })
      }
    },
    // 放大/缩小
    changeScaleHandle(num) {
      num = num || 1;
      this.$refs.cropper.changeScale(num);
    },
    // 左旋转
    rotateLeftHandle() {
      this.$refs.cropper.rotateLeft();
    },
    // 右旋转
    rotateRightHandle() {
      this.$refs.cropper.rotateRight();
    },
    // 下载
    downloadHandle(type) {
      let aLink = document.createElement('a');
      aLink.download = 'author-img';
      if (type === 'blob') {
        this.$refs.cropper.getCropBlob((data) => {
          let downImg = window.URL.createObjectURL(data);
          aLink.href = window.URL.createObjectURL(data);
          aLink.click();
        })
      } else {
        this.$refs.cropper.getCropData((data) => {
          let downImg = data;
          aLink.href = data;
          aLink.click();
        })
      }
    },
    // 清理图片
    clearImgHandle() {
      this.option.img = '';
    },
    // 截图框移动回调函数
    cropMoving(data) {
      // 截图框的左上角 x，y和右下角坐标x，y
      // let cropAxis = [data.axis.x1, data.axis.y1, data.axis.x2, data.axis.y2]
    },
    async finish() {
      // 获取截图的 blob 数据

      this.$refs.cropper.getCropData((data) => {
        this.loading = true;
        this.dialogVisible = false;
        this.previewImg = data;
        this.isPreview = true;
        let arr = data.split(',');
        let mime = arr[0].match(/:(.*?);/)[1];
        let suffix = mime.split('/')[1];
        let bstr = atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        let file = new File([u8arr], `${this.fileName}.${suffix}`, {
          type: mime
        });
        this.uploadTecent(file).then(url => {
          this.ClubCourseForm.Cover = url;
        });
        this.loading = false;
      })
    },
  },
};
</script>
<style lang='scss' scoped>
/deep/.el-table__body tr,
.el-table__body td {
  height: 54px !important;
}
.cropper-content {
  .cropper {
    width: auto;
    height: 350px;
  }
}
.action-box {
  display: flex;
  justify-content: space-between;
  button {
    margin-left: 0;
  }
  margin-top: 24px;
}
/deep/.el-dialog__footer {
  padding-right: 24px !important;
}
</style>
